<template>
  <div>
    <v-overlay :value="overlay" absolute style="z-index: 9999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>POD # </v-toolbar-title>
        <v-col class="text-center">
          <v-btn small color="primary" @click="guardarPod">
            <v-icon left>mdi-content-save</v-icon> Guardar
          </v-btn>

          <v-btn color="blue" dark class="mx-4" small @click="imprimirPod">
            <v-icon left>mdi-printer</v-icon> Imprimir
          </v-btn>

          <v-btn color="blue" dark class="mx-4" small @click="abrirMensaje">
            <v-icon left>mdi-send</v-icon> Enviar
          </v-btn>

          <v-btn color="blue" dark class="mx-4" small @click="descargarXml">
            <v-icon left>mdi-download</v-icon> Descargar XML
          </v-btn>
        </v-col>
      </v-toolbar>

      <v-card-text class="px-6 mt-4">
        <v-form ref="formPod">
          <v-row>
            <v-col class="pt-0 pb-0" cols="6">
              <v-text-field
                :rules="requiredRule"
                v-model="datosPodHead.POD_FECHA"
                label="Fecha"
              >
              </v-text-field>
            </v-col>

            <v-col class="pt-0 pb-0" cols="3">
              <v-text-field
                :rules="requiredRule"
                v-model="datosPodHead.AWB_NUM"
                label="AWB"
              >
              </v-text-field>
            </v-col>
            <v-col class="pt-0 pb-0" cols="3">
              <v-text-field v-model="datosPodHead.ORIGEN" label="Orígen">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0" cols="6">
              <v-text-field
                v-model="datosPodHead.CF_NOMBRE"
                label="Cliente / Consignee"
              >
              </v-text-field>
            </v-col>

            <v-col class="pt-0 pb-0" cols="3">
              <v-text-field
                v-model="datosPodHead.TRANSP_NOMBRE"
                label="Transportista / Carrier"
              >
              </v-text-field>
            </v-col>
            <v-col cols="1" class="pt-0 pb-0">
              <v-text-field
                label="Día transporte"
                v-model="datosPodHead.DIA_TRANSPORTE"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0 pb-0" cols="2">
              <v-text-field
                v-model="datosTransp.CODIGO_CUENTA"
                label="Codigo cuenta"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0" cols="6">
              <v-autocomplete
                :rules="requiredRule"
                :items="cnees"
                item-text="CNEE_NOMBRE"
                item-value="ENTE_ID"
                v-model="datosPodHead.CNEE_ID"
                label="Shipper"
              >
              </v-autocomplete>
            </v-col>

            <v-col class="pt-0 pb-0" cols="6">
              <v-text-field
                v-model="datosTransp.OBSERVACIONES_ENVIO"
                label="Observaciones transportista"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-alert color="#ececec" class="mt-4">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  autofocus
                  tabindex="0"
                  v-model="codigoBarraItem"
                  label="Código de barra"
                  ref="inputBarcode"
                  @keydown.enter="ingresarCajas()"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-alert>
          <v-row>
            <v-col cols="12">
              <v-data-table :items="itemsPod" :headers="headerItemsPod">
                <template v-slot:item.opcion="{ item }">
                  <v-btn @click="borrarItemPod(item)" small color="red" icon
                    ><v-icon left>mdi-delete</v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- dialog mensaje correo-->
    <v-dialog v-model="dialogMensajeCorreo" width="650" scrollable>
      <v-card>
        <v-toolbar color="blue" flat dark height="40">
          <v-icon class="mx-1">mdi-email</v-icon>
          <span class="headline">Enviar POD por email </span>
          <v-spacer></v-spacer>
          <v-btn @click="dialogMensajeCorreo = !dialogMensajeCorreo" small icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form ref="formMensajeCorreo">
            <v-row class="mt-1 mb-0">
              <v-col class="pt-1 pb-0" cols="12">
                <v-file-input
                  style="display: none"
                  multiple
                  id="archivosAdjuntos"
                  label="adjuntar"
                  v-model="archivoSelecionados"
                ></v-file-input>

                <v-textarea
                  class="md-12"
                  small
                  v-model="datosMensaje.destinatarios"
                  label="Destinatarios"
                  :rules="requiredRule"
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row class="pt-0 mt-0">
              <v-col class="pt-0 mt-0 pb-1" cols="12">
                <v-text-field
                  label="Asunto"
                  v-model="datosMensaje.asunto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pt-0 pb-0" cols="12">
                <v-textarea
                  class="md-12"
                  small
                  rows="4"
                  v-model="datosMensaje.mensaje"
                  label="Mensaje"
                  :rules="requiredRule"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="py-0 my-0">
              <v-col cols="4" class="pl-4 pb-0 mb-0">
                <span>Adjuntos ({{ archivoSelecionados.length }})</span>
              </v-col>
              <v-col cols="8" class="pb-0 mb-0">
                <v-checkbox
                  class="ma-0 pa-0"
                  v-model="noPOD"
                  label="No adjuntar POD"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="mt-0 pt-0">
              <v-col cols="12" class="ma-0 pa-0">
                <v-list
                  dense
                  nav
                  class="pa-0 ma-0"
                  style="height: 20px; min-height: 20px"
                >
                  <v-list-item
                    style="height: 20px; min-height: 20px"
                    clas="pa-0 ma-0"
                    v-for="(item, i) in archivoSelecionados"
                    v-bind:key="i"
                  >
                    <v-list-item-avatar
                      style="height: 20px; min-height: 20px"
                      class="align-self-center pa-0 ma-0"
                      color="white"
                    >
                      <v-btn color="red" icon x-small @click="borrarAdjunto(i)"
                        ><v-icon small>mdi-delete</v-icon></v-btn
                      >
                    </v-list-item-avatar>
                    <v-list-item-avatar
                      style="height: 20px"
                      class="align-self-center pa-0 ma-0"
                      color="white"
                    >
                      <v-icon small color="#000">mdi-attachment</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content class="pa-0 ma-0">
                      <v-list-item-title class="pa-0 ma-0 text-uppercase">
                        <template v-slot:item.archivo="{ item }"></template>
                        {{ item.name }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mb-2 py-0 my-0" fixed>
          <v-col class="text-left py-0 my-0">
            <v-col>
              <v-btn small color="primary" @click="abrirAdjuntos()">
                <v-icon>mdi-attachment</v-icon> Adjuntar
              </v-btn>
            </v-col>
          </v-col>
          <v-col class="text-right py-0 my-0">
            <v-btn small color="primary" @click="enviarMensajeAdjuntos">
              <v-icon>mdi-send</v-icon> Enviar
            </v-btn>

            <v-btn
              class="ml-4"
              small
              color="info"
              @click="dialogMensajeCorreo = !dialogMensajeCorreo"
            >
              <v-icon>mdi-exit-to-app</v-icon> Salir
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";
import axios from "axios";
var intervalbarCode = "";

export default {
  name: "PodForm",
  data: () => ({
    requiredRule: [(v) => !!v || "El campo es requerido"],
    emailRules: [
      (v) =>
        String(v)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || "Ingrese un Email válido",
    ],
    listaPod: [],
    options: {},

    search: null,

    //dialogPod: false,
    datosPodHead: {
      POD_ID: "",
      POD_FECHA: "",
      POD_ESTADO: "",
      TRANSP_NOMBRE: "",
    },

    itemsPod: [],
    headerItemsPod: [
      { text: "Opciones", value: "opcion" },
      { text: "HAWB", value: "NUM_GUIA_HIJA" },
      { text: "FINCA", value: "FINCA_NOMBRE" },
      { text: "Descripción", value: "DESCRIPCION" },
      { text: "Tipo de caja", value: "TIPO_CAJA" },
      { text: "Dimensiones", value: "DIM" },
      { text: "Piezas", value: "PIEZAS", align: "end" },
      { text: "Full box", value: "FBE", align: "end" },
    ],

    itemsPerPage: 40,
    currentPage: 1,
    creaPod: false,
    codigoBarraItem: "",
    cfinales: [],
    cnees: [],
    dialogMensajeCorreo: false,
    datosMensaje: { destinatarios: "", mensaje: "", asunto: "" },
    datosTransp: {},
    contactos: [],
    destinatarios: {},
    attachments: [{ archivo: "POD" }],
    archivoSelecionados: [],
    noPOD: false,
    overlay: false,
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "token", "url"]),
    ...mapGetters("access", [""]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    modificarPodHead() {
      this.setUrl("api/modificar-pod-head");
      this.requestApi({
        method: "POST",
        data: {
          pod_head: this.datosPodHead,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarPod();
          //this.dialogPod = false;
        })
        .then(() => {});
    },

    guardarPod() {
      if (!this.$refs.formPod.validate()) {
        return false;
      }
      if (this.creaPod == true) {
        this.crearPod();
      } else {
        this.modificarPodHead();
      }
    },

    crearPod() {
      console.log(this.datosPod);
      this.setUrl("api/crear-pod");
      this.requestApi({
        method: "POST",
        data: {
          pod_head: this.datosPod,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          //this.dialogPod = false;
          this.cargarPod();
        })
        .then(() => {});
    },

    cargarPod() {
      this.setUrl("api/cargar-datos-pod");
      this.requestApi({
        method: "GET",
        data: {
          POD_ID: this.$route.params.podId,
        },
      })
        .then((res) => {
          this.datosPodHead = res.data.podHead;
          this.itemsPod = res.data.podItems;
          this.cfinales = res.data.clientesFinales;
          this.cnees = res.data.consignatarios;
          this.datosMensaje.destinatarios = res.data.strContactos;
          this.datosTransp = res.data.infoTransp;
        })
        .then(() => {});
    },

    ingresarCajas() {
      //clearInterval(intervalbarCode);

      //intervalbarCode = setTimeout(() => {
      // let obj = this.dataQuotation.items_order.find(
      //   (e) => e.codigoBarra == this.barCodeItem && e.verificado == false
      // );

      // if (typeof obj != "undefined") {
      //   let index = this.dataQuotation.items_order.indexOf(obj);
      //   this.dataQuotation.items_order[index].verificado = true;
      // } else {
      //   Vue.swal({
      //     html: "El producto escaneado no pertence a la cotización",
      //     icon: "warning",
      //     ...this.paramAlertQuestion,
      //   });
      // }

      this.setUrl("api/agregar-pod-item-bodega");
      this.requestApi({
        method: "POST",
        data: {
          ID: this.codigoBarraItem,
          POD_ID: this.datosPodHead.POD_ID,
        },
      })
        .then((res) => {
          //console.log(res);
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarPod();
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
      this.codigoBarraItem = null;
      //}, 200);
    },

    imprimirPod() {
      this.setUrl("api/imprimir-pod");
      this.requestApi({
        method: "POST",
        data: {
          POD_ID: this.datosPodHead.POD_ID,
        },
      })
        .then((res) => {
          let a = document.createElement("a");
          a.href = "data:application/pdf;base64," + res.data.datos;
          a.download = res.data.nombreArchivo;
          a.click();
          this.itemsSel = [];

          //this.alertNotification({ param: { html: res.data.msg } });
        })
        .then(() => {});
    },
    enviarMensajeCorreo() {
      //alert(this.token);

      this.setUrl("api/enviar-pod-email");
      let archivos = [];
      for (let i = 0; i < this.archivoSelecionados.length; i++) {
        archivos.push(this.archivoSelecionados[i]);
      }

      this.requestApi({
        method: "POST",
        data: {
          POD_ID: this.datosPodHead.POD_ID,
          destinatarios: this.datosMensaje.destinatarios,
          mensaje: this.datosMensaje.mensaje,
          subject: this.datosMensaje.asunto,
        },
      })
        .then((res) => {
          this.dialogMensajeCorreo = false;
          this.alertNotification({ param: { html: res.data.msg } });
        })
        .then(() => {});
    },
    borrarItemPod(item) {
      Vue.swal({
        html: "Está seguro de borrar este item  ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-pod-item");
          this.requestApi({
            method: "POST",
            data: {
              POD_ID: this.datosPodHead.POD_ID,
              NUM_GUIA_HIJA: item.NUM_GUIA_HIJA,
              TCAJA_ID: item.TCAJA_ID,
            },
          })
            .then((res) => {
              this.dialogMensajeCorreo = false;
              this.cargarPod();
              this.alertNotification({ param: { html: res.data.msg } });
            })
            .then(() => {});
        }
      });
    },
    abrirMensaje() {
      this.datosMensaje.asunto =
        "POD #" +
        this.datosPodHead.POD_ID +
        " | AWB " +
        this.datosPodHead.AWB_NUM +
        " - " +
        this.datosPodHead.CF_NOMBRE;
      this.datosMensaje.mensaje =
        "Dear customer, \n\n Enclosed you will find Proof of Delivery for your shipment. \n\nRegards,\nExpress Global Logistics\n(786) 858-0300";

      this.dialogMensajeCorreo = true;
      this.archivoSelecionados = [];
    },

    enviarMensajeAdjuntos() {
      //console.log(this.archivoSelecionados);

      let tamanoTotal = 0;
      for (let i = 0; i < this.archivoSelecionados.length; i++) {
        tamanoTotal += this.archivoSelecionados[i].size;
      }

      if (this.archivoSelecionados.length > 10 && tamanoTotal < 10000000) {
        alert("Va a cargar muchos archivos está seguro ? ");
        return false;
      }

      if (tamanoTotal >= 100000000) {
        alert("Va a cargar archivos por más de 100MB está seguro ? ");
        return false;
      }

      let formData = new FormData();

      for (let i = 0; i < this.archivoSelecionados.length; i++) {
        formData.append("archivos[]", this.archivoSelecionados[i]);
      }

      formData.append("user_name", this.user.user_login_id);
      formData.append("empresa_id", this.user.empresa_id);
      formData.append("subject", this.datosMensaje.asunto);
      formData.append("mensaje", this.datosMensaje.mensaje);
      formData.append("destinatarios", this.datosMensaje.destinatarios);
      formData.append("POD_ID", this.datosPodHead.POD_ID);
      formData.append("noPOD", this.noPOD);

      //console.log(this.archivoSelecionados);

      this.overlay = true;
      this.setUrl("api/enviar-pod-email");
      //alert(this.token);

      axios
        .post(this.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.overlay = false;

          //this.cargarDatosOportunidad(this.$route.params.sales_opportunity_id);
          this.alertNotification({
            param: {
              html: res.data.msg,
            },
          });
        })
        .catch((err) => {
          this.overlay = false;
        })
        .finally((err) => {
          this.overlay = false;
        });
      //alert("xxx" + this.token);
    },
    abrirAdjuntos() {
      document.getElementById("archivosAdjuntos").click();
    },
    borrarAdjunto(i) {
      this.archivoSelecionados.splice(i, 1);
    },

    descargarXml() {
      this.setUrl("api/descargar-xml-pod");

      this.requestApi({
        method: "POST",
        data: {
          podId: this.datosPodHead.POD_ID,
          transpId: this.datosPodHead.TRANSPORTISTA_ID,
        },
      })
        .then((res) => {
          let a = document.createElement("a");
          a.href = "data:application/xml;base64," + res.data.datos.datos;
          a.download = res.data.datos.nombreArchivo;
          a.click();
          this.alertNotification({ param: { html: res.data.msg } });
        })
        .then(() => {});
    },
  },

  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("Proof of delivery POD");
    this.cargarPod();
  },
};
</script>
